.postSlider {
  @apply relative;
}

.overlay {
  &:after {
    @apply content-[""] top-0 left-0 absolute w-full h-full bg-black-100 opacity-50;
  }
}

.green {
  @apply py-10 bg-green-50 text-black-100;
}

.lime {
  @apply py-10 bg-summer-green-50 text-black-100;
}

.white {
  @apply py-10 bg-white text-black-100;
}

.good-light-green {
  @apply py-10 bg-good-green-10 text-black-100;
}

.good-light-blue {
  @apply py-10 bg-good-blue-10 text-black-100;
}

.good-light-green {
  @apply py-10 bg-good-green-10 text-black-100;
}

.image {
  @apply py-10 md:py-10;
}
