.heading {
  & h2 {
    @apply relative z-10;
  }

  & p {
    @apply relative z-10 max-w-1xl pt-4 text-base md:text-lg;
  }
}

.slider {
  @apply mt-5 md:mt-7.5 lg:mt-10;
}

.slide {
  @apply px-0 max-w-xxs sm:max-w-xs md:max-w-none;
}

.controllers {
  @apply flex mt-10 justify-between relative z-10;
}

.navigation {
  @apply flex items-center ml-6;
}

.button {
  @apply z-10 w-13 h-13 text-center bg-white rounded-full cursor-pointer;
  @apply hover:bg-black-25;

  & svg {
    @apply w-5.5 h-5.5 mx-auto;
  }

  &:global(.prev) {
    @apply mr-4;

    & svg {
      @apply transform rotate-180;
    }
  }

  &:disabled {
    @apply pointer-events-none border-black-50;

    & svg {
      @apply text-black-50;
    }
  }
}

.cta {
  @apply lg:mr-6 min-w-32 whitespace-normal text-center;
}

.scrollbarWrapper {
  @apply relative items-center flex-1 hidden lg:flex;
}

.scrollbar {
  @apply w-full relative h-[2px];
}

.swiperScrollbar {
  background-color: hsla(0, 0%, 100%, 0.6);
  height: 0.125rem !important;

  & :global(.swiper-scrollbar-drag) {
    @apply bg-good-green-100;
  }
}

.bgimage {
  @apply text-white;
}
